class TableDisplay extends csync.Report.Display {
  constructor(report) {
    super();
    this.report = report;
  }

  show_totals(row_or_col) {
    // Show totals if the opposite header is defined
    return this.report.attribs.type.match(/TallyReport/) && this.report.attribs.headers[row_or_col === 'row' ? 'col' : 'row'].title;
  }

  format_percent(frac) {
    // Format a given fraction as a percentage
    return `${(frac * 100).toFixed(1)}%`;
  }

  render() {
    const { data, headers } = this.report.attribs;
    const tbl = this.tbl = $('<table>');

    // Column label row
    if (headers.col && headers.col.title) {
      let trow = $('<tr>');

      // Blank cells for row grouping label and row header, if necessary
      if (headers.row) {
        if (headers.row.title) $('<th>').appendTo(trow);
        $('<th>').appendTo(trow);
      }

      // Col grouping label
      $('<th>').addClass('col_grouping_label')
        .attr('colspan', headers.col.cells.length)
        .text(headers.col.title)
        .appendTo(trow);

      // Row total cell
      if (this.show_totals('row')) $('<th>').appendTo(trow);

      tbl.append(trow);
    }

    // Header row
    if (headers.col || (headers.row && headers.row.cells.length > 1)) {
      let trow = $('<tr>');

      // Blank cells for row grouping label and row header, if necessary
      if (headers.row) {
        if (headers.row.title) $('<th>').appendTo(trow);
        $('<th>').appendTo(trow);
      }

      // Rest of header cells
      if (headers.col) {
        headers.col.cells.forEach(ch => {
          $('<th>').addClass('col').html(ch.name || `[${i18n.t('report/report.blank')}]`).appendTo(trow);
        });
      }

      // Row total header
      if (this.show_totals('row')) {
        $('<th>').addClass('row_total').text(i18n.t('common.total')).appendTo(trow);
      }

      tbl.append(trow);
    }

    // Create (but don't insert yet) the row grouping label
    let row_grouping_label;
    if (headers.row && headers.row.title) {
      const txt = headers.row.title.replace(/\s+/g, '<br/>');
      row_grouping_label = $('<th>').addClass('row_grouping_label').attr('rowspan', headers.row.cells.length);
      row_grouping_label.append($('<div>').html(txt));
    }

    // Body
    data.rows.forEach((data_row, r) => {
      let trow = $('<tr>');

      // Add the row grouping label if defined (also delete it so it doesn't get added again)
      if (row_grouping_label) {
        trow.append(row_grouping_label);
        row_grouping_label = null;
      }

      // Row header
      if (headers.row) {
        $('<th>').addClass('row').html(headers.row.cells[r].name || `[${i18n.t('report/report.blank')}]`).appendTo(trow);
      }

      // Row cells
      data_row.forEach((cell, c) => {
        let val = cell == null ? '' : cell;
        const typ = typeof val;

        // Calculate percentage if necessary
        if (val !== '' && this.report.attribs.percent_type !== 'none') {
          switch (this.report.attribs.percent_type) {
            case 'overall':
              val /= data.totals.grand;
              break;
            case 'by_row':
              val /= data.totals.row[r];
              break;
            case 'by_col':
              val /= data.totals.col[c];
              break;
          }
          val = this.format_percent(val);
        }

        $('<td>').html(val).addClass(typ).appendTo(trow);
      });

      // Row total
      if (this.show_totals('row')) {
        let val = data.totals.row[r] === 0 ? '' : data.totals.row[r];

        if (this.report.attribs.percent_type !== 'none' && val !== '') {
          val = this.format_percent(val / data.totals.grand);
        }

        $('<td>').addClass('row_total').text(val).addClass('number').appendTo(trow);
      }

      tbl.append(trow);
    });

    // Footer
    if (this.show_totals('col')) {
      let trow = $('<tr>');

      // Blank cells for row grouping label, if necessary
      if (headers.row && headers.row.title) $('<th>').appendTo(trow);

      // Row header
      if (headers.row) {
        $('<th>').addClass('row col_total').text(i18n.t('common.total')).appendTo(trow);
      }

      // Row cells
      data.totals.col.forEach((ct, c) => {
        let val = ct === 0 ? '' : ct;

        if (this.report.attribs.percent_type !== 'none' && val !== '') {
          val = this.format_percent(val / data.totals.grand);
        }

        $('<td>').addClass('col_total').text(val).addClass('number').appendTo(trow);
      });

      // Grand total
      if (this.show_totals('row')) {
        let val = data.totals.grand > 0 ? data.totals.grand : '';

        if (this.report.attribs.percent_type !== 'none' && val !== '') {
          val = this.format_percent(1);
        }

        $('<td>').addClass('row_total col_total').text(val).addClass('number').appendTo(trow);
      }

      tbl.append(trow);
    }

    // Add a row count
    $('.report-info').append($('<div>').attr('id', 'row_count').text(this.i18n_total_rows_label(data)));

    // Add the table
    $('.report-body').empty().append(tbl);

    this.equalize_col_widths();
  }

  equalize_col_widths() {
    const extra_spc = $('.report-body').position().left + $('.report-body').width() - this.tbl.position().left - this.tbl.width();
    const cur_widths = this.tbl.find('th.col').map(function () { return $(this).width(); }).get();

    if (cur_widths.length === 0) return;

    const largest_current = Math.max(...cur_widths);
    const cur_sum = cur_widths.reduce((sum, width) => sum + width, 0);
    const largest_allowable = (cur_sum + extra_spc) / cur_widths.length;
    const optimal = Math.min(largest_current, largest_allowable) + 1;

    this.tbl.find('th.col').width(optimal);
  }

  i18n_total_rows_label(data) {
    const resp_tally_or_list = this.report.attribs.type.match(/ListReport/) || this.is_response_tally_report(this.report);
    let msg = i18n.t('report/report.total_rows', { count: data.rows.length });
    if (data.truncated) msg += ` (${i18n.t('common.clipped')})`;
    return msg;
  }

  is_response_tally_report(report) {
    return report.attribs.type.match(/Report::TallyReport/) && report.attribs.tally_type.match(/Response/);
  }
}

csync.Report.TableDisplay = TableDisplay;
