/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
csync.Views.UserProfileFormView = class UserProfileFormView extends csync.Views.ApplicationView {
  get el() { return 'form.user_form'; }

  get events() {
    return {
      'change select#user_reset_password_method': 'toggle_password_fields',
    };
  }

  initialize(params) {
    this.params = params || {};
    this.init_user_group_select();
    return this.toggle_password_fields();
  }

  init_user_group_select() {
    const option_builder = new csync.Utils.Select2OptionBuilder();
    return this.$('#user_user_group_ids').select2({
      theme: 'bootstrap-5',
      tags: true,
      templateResult: this.format_suggestions,
      ajax: option_builder.ajax(this.params.user_group_options_url, 'possible_groups', 'name'),
    });
  }

  format_suggestions(item) {
    if (item.id === item.text) {
      return $(`<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-lg" viewBox="0 0 16 16">
      <path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2"/>
    </svg>${item.text} <span class="details create_new">[${i18n.t('user_group.new_group')}]</span>` + '</li>');
    }
    return item.text;
  }

  toggle_password_fields(event) {
    const select_value = this.$('select#user_reset_password_method').val();
    return this.$('.password-fields').toggleClass('d-none', (select_value !== 'enter') && (select_value !== 'enter_and_show'));
  }
};
