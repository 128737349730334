import $ from 'jquery'; 

// DOM element selectors
const selectors = {
  body: 'body',
  menuToggle: '#menu_toggle',
  sidebarMenu: '.sidebar-menu',
  leftCol: '.left-col',
  rightCol: '.right-col',
  navMenu: '.nav_menu',
  footer: 'footer',
};

// DOM elements
const DOM = {
  currentUrl: window.location.pathname.split('#')[0].split('?')[0],
  body: $(selectors.body),
  menuToggle: $(selectors.menuToggle),
  sidebarMenu: $(selectors.sidebarMenu),
  leftCol: $(selectors.leftCol),
  rightCol: $(selectors.rightCol),
  navMenu: $(selectors.navMenu),
  footer: $(selectors.footer),
};

// Sidebar functionality
const sidebar = {
  setContentHeight() {
    const bodyHeight = DOM.body.outerHeight();
    const footerHeight = DOM.footer.outerHeight();
    const leftColHeight = DOM.leftCol.eq(1).outerHeight();
    const contentHeight = Math.max(bodyHeight, leftColHeight) - (DOM.navMenu.outerHeight() + footerHeight);
    DOM.rightCol.css('min-height', contentHeight);
  },

  openUpMenu() {
    DOM.sidebarMenu.find('li').removeClass('active');
    DOM.sidebarMenu.find('li ul').slideUp();
  },

  handleMenuToggleClick() {
    DOM.menuToggle.on('click', () => {
      sidebar.toggleActiveState();
    });
  },

  highlightCurrentPage() {
    let path = DOM.currentUrl;
    let found = DOM.sidebarMenu.find(`a[href="${path}"]`).length > 0;

    while (!found && path.lastIndexOf('/') > 0) {
      path = path.substring(0, path.lastIndexOf('/'));
      found = DOM.sidebarMenu.find(`a[href="${path}"]`).length > 0;
    }

    if (found) {
      const currentLink = DOM.sidebarMenu.find(`a[href="${path}"]`);
      currentLink.parent('li').addClass('current-page')
        .parents('ul').slideDown().parent().addClass(DOM.body.hasClass('nav-parent') ? 'active' : '');
    }
  },

  init() {
    // Uncomment to enable the menu item click handler
    this.handleMenuToggleClick();
    this.highlightCurrentPage();
    this.setContentHeight();
  }
};

$(document).on('turbo:load', function(e) {
  // Initialize the sidebar
  sidebar.init();

  // Initialize Select2 with Bootstrap 5 theme
  $('#change-project.select2').select2({
      theme: 'bootstrap-5'
    }
  );

  // Initialize Bootstrap tooltips with animation
  const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
  const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => 
    new bootstrap.Tooltip(tooltipTriggerEl, {
      animation: true
    })
  );
});

