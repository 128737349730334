//

import ClipboardJS from 'clipboard';

document.addEventListener("turbo:load", function () {
  var clipboard = new ClipboardJS('.copy_to_clipboard');

  clipboard.on('success', function (e) {
    var trigger = e.trigger; // The button that triggered the copy action
    var originalText = trigger.innerHTML; // Store the original button content

    // Replace button content with "Copied!"
    trigger.innerHTML = i18n.t("common.copied");

    // Restore the original button content after 3 seconds
    setTimeout(function () {
      trigger.innerHTML = originalText;
    }, 3000);

    e.clearSelection(); // Clear text selection
  });

  // Prevent the default click action
  document.querySelectorAll('.copy_to_clipboard').forEach(function(button) {
    button.addEventListener('click', function(e) {
      e.preventDefault();
    });
  });
});
