window.ApexCharts = require("apexcharts");

//Set locales globally across all charts
let localeName = document.documentElement.lang || 'fr';

try {
    var lang = require(`apexcharts/dist/locales/${localeName}.json`);

    Apex.chart = {
        locales: [lang],
        defaultLocale: localeName
    };
} catch (error) {
    console.error(`Locale file for ${localeName} not found.`, error);
}
