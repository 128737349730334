import _ from "underscore";
import { TurboConfirm } from "@rolemodel/turbo-confirm";

const tc = new TurboConfirm({
    activeClass: "show"
});

class BatchActionsView extends csync.Views.ApplicationView {
    get el() {
        return ".csync-table-responsive";
    }

    initialize(params, search_form_view) {
        this.action_links = $(".index-links");
        this.form = this.$el.find("form.index-table-form").length > 0
            ? this.$el.find("form.index-table-form")
            : this.$el.closest("form.index-table-form");
        this.select_all_pages_field = this.$el.find("input[name=select_all_pages]");
        this.alert = this.$el.find("div.select-notice");
        this.class_name = {
            one_selected: i18n.t(`activerecord.models.${params.class_name}.one`),
            many_selected: i18n.t(`activerecord.models.${params.class_name}.many`)
        };        
        this.search_form_view = search_form_view;
        this.entries = this.$el.data("entries");
        this.items = this.$el.data("items");
        this.pages = this.$el.data("pages");

        if (params.modified_obj_id) {   
            this.flashModifiedElement(params.class_name, params.modified_obj_id);
        }

        this.bindEvents();

        if (params.batch_ops) {
            this.updateLinks();
        }
    }

    bindEvents() {
        $(document).on("click", "#select-all-link", this.selectAllClicked.bind(this));
        $(document).on("click", "a.select_all_pages", this.selectAllPagesClicked.bind(this));
        $(document).on("click", "a.deselect_all_pages", this.deselectAllPagesClicked.bind(this));
        $(document).on("click", "a.batch-submit-link", this.submitBatch.bind(this));
        $(document).on("change", "input[type=checkbox].batch_op", this.checkboxChanged.bind(this));
    }

    flashModifiedElement(className, objId) {
        const selector = `#${className}_${objId}`;
        const $element = $(selector);

        $element.addClass("highlight");

        setTimeout(() => {
            $element.removeClass("highlight");
        }, 2000);
    }

    selectAllClicked(event) {
        event.preventDefault();
        const checkboxes = this.getBatchCheckboxes();
        const allChecked = this.allChecked();

        this.toggleAllBoxes(!allChecked);
        this.updateSelectAllPagesFieldValue();
        this.updateLinks();
    }

    selectAllPagesClicked(event) {
        event.preventDefault();
        this.toggleAllBoxes(true);
        this.select_all_pages_field.val("1");
        this.updateLinks();
    }

    deselectAllPagesClicked(event) {
        event.preventDefault();
        this.toggleAllBoxes(false);
        this.select_all_pages_field.val("");
        this.updateLinks();
    }

    checkboxChanged() {
        this.updateSelectAllPagesFieldValue();
        this.updateLinks();
    }

    someChecked() {
        const checkboxes = this.getBatchCheckboxes();
        const checked = _.filter(checkboxes, (cb) => cb.checked);
        return checked.length > 0 && checked.length < checkboxes.length;
    }

    updateLinks() {
        let label;
        let inputType;
    
        if (this.allChecked()) {
            label = "select_all";
            inputType = "checkbox";
        } else if (this.someChecked()) {
            label = "some_select";
            inputType = "checkbox";
        } else {
            label = "deselect_all";
            inputType = "checkbox";
        }
    
        const inputElement = document.createElement("input");
        inputElement.setAttribute("type", inputType);
        inputElement.checked = this.allChecked();
        inputElement.indeterminate = this.someChecked();
    
        const selectAllLink = document.getElementById("select-all-link");
    
        if (selectAllLink) {
            selectAllLink.innerHTML = "";
            selectAllLink.appendChild(inputElement);
            selectAllLink.setAttribute("title", i18n.t(`layout.${label}`));
        }

        this.alert.hide().html("").removeAttr("style");
        
        const selectedCount = this.getSelectedCount();
        const totalCount = Math.min(this.entries, this.items);
        let msg = "";
        let actionLink = "";
    
        if (selectedCount >= this.items && this.select_all_pages_field.val()) {
            msg = i18n.t("index_table.messages.selected_items.other", {
                class_name: this.class_name.many_selected.toLowerCase(),
                count: totalCount
            });
            actionLink = `<a href='#' class='btn btn-primary deselect_all_pages'>${i18n.t("layout.deselect_all")}</a>`;
        } else if (this.pages >= 1 && selectedCount > 0) {
            if (selectedCount === 1) {
                msg = i18n.t("index_table.messages.selected_items.one", {
                    class_name: this.class_name.one_selected.toLowerCase(),
                });
            } else {
                msg = i18n.t("index_table.messages.selected_items.other", {
                    class_name: this.class_name.many_selected.toLowerCase(),
                    count: selectedCount
                });
            }
    
            actionLink = selectedCount !== this.entries
                ? `<a href='#' class='btn btn-primary select_all_pages'>${i18n.t("layout.select_all")}</a>`
                : `<a href='#' class='btn btn-primary deselect_all_pages'>${i18n.t("layout.deselect_all")}</a>`;
        }
    
        if (msg && selectedCount > 0) {
            this.alert.html(`<span class="btn-label text-bg-label-dark">${msg}</span> ${actionLink}`).show();
        }
    
        this.updateBatchLinks();
    }

    updateBatchLinks() {
        const selectedCount = this.getSelectedCount();
        const batchLinks = this.action_links.find("button#batch-actions-button");

        batchLinks.prop("disabled", selectedCount === 0).attr("aria-disabled", selectedCount === 0);
    }

    getBatchCheckboxes() {
        return this.form.find("input[type=checkbox].batch_op");
    }

    getSelectedCount() {
        return this.select_all_pages_field.val() ? this.entries : _.size(_.filter(this.getBatchCheckboxes(), (cb) => cb.checked));
    }

    toggleAllBoxes(state) {
        const checkboxes = this.getBatchCheckboxes();
        checkboxes.each((_, cb) => {
            cb.checked = state;
        });
    }

    allChecked() {
        const checkboxes = this.getBatchCheckboxes();
        return _.every(checkboxes, (cb) => cb.checked);
    }

    updateSelectAllPagesFieldValue() {
        this.select_all_pages_field.val(this.allChecked() ? "1" : "");
    }

    submitBatch(event) {
        event.preventDefault();
    
        const options = $(event.target).data();
        const selected = this.getSelectedCount();
    
        if (selected === 0) {
            this.alert.html(i18n.t("layout.no_selection"))
                .addClass("alert-danger")
                .show();
            return false;
        }
    
        const confirmTitle = options.confirmTitle ? options.confirmTitle : null;
        const confirmMessage = options.turboConfirm ? i18n.t(options.turboConfirm, { count: selected }) : null;
        const confirmButton = options.confirmButton ? options.confirmButton : null;
    
        const proceed = () => {
            const form = $('<form>').attr('action', options.path).attr('method', 'post').attr('style', 'display: none');
    
            form.append(this.form.find('input.batch_op:checked').clone());
            form.append(this.form.find('input[name=select_all_pages]').clone());
            const pagesField = this.form.find('input[name=pages]');
            pagesField.val(this.pages);
            form.append(pagesField.clone());
            if (this.search_form_view) {
                form.append(this.search_form_view.$el.find('input[name=search]').clone());
            }
    
            const token = $('meta[name="csrf-token"]').attr('content');
            $('<input>').attr({ type: 'hidden', name: 'authenticity_token', value: token }).appendTo(form);
    
            form.appendTo($('body'));
            form.submit();
        };
        
        if (options.turboMethod) {
            tc.confirmWithContent({
                "#confirm-title": confirmTitle,
                "#confirm-body": (confirmMessage ? confirmMessage : "") + `<p class="mt-4">${options.turboMethod === "delete" ? i18n.t("common.deleted_unrecoverable") : ""}</p>`,
                "#confirm-accept": confirmButton
            }).then((response) => {
                if (response) {
                    proceed();
                } else {
                    $("#confirm").hide();
                }
            });
        } else if (options.path) {
            proceed();
            $("#confirm").hide();
        } else {
            tc.close();
        }
    }
}

csync.Views.BatchActionsView = BatchActionsView;
