// Handles general client-side functionality that appears in the template (e.g., language chooser form)
// appears at top of file because it needs to be loaded first

const ALERT_CLASSES = {
    notice: "alert-info",
    success: "alert-success",
    error: "alert-danger",
    alert: "alert-warning",
};

class App {
    // constructor
    constructor(params) {
        this.params = params;

        // Setup the UrlBuilder instance for all to use.
        this.url_builder = new csync.UrlBuilder({
            locale: this.params.locale,
            mode: this.params.mode,
            project_name: this.params.project_name,
        });

        this.initialize();
    }

    initialize() {
        // setup the language change form and link
        this.setupLocaleForm();

        // setup submit response dropdown in nav bar
        this.setupSubmitResponseDropdown();

        // set session countdown
        this.resetSessionCountdown();

        // prevent double submission of any forms on the page
        this.preventDoubleSubmission();

        // listen for any ajax calls so we can update the session countdown
        // but don't update the countdown if the auto param is set, because those don't count
        $(document).on("ajaxComplete", (event, xhr, ajaxopts) => {
            if (!ajaxopts.url.includes("auto=1")) this.resetSessionCountdown();
        });

        // Signal when the user is navigating to a different page, because that
        // causes XHR requests to fail in some browsers, and we can ignore those failures.
        $(window).on("beforeunload", () => {
            csync.unloading = true;
        });

        this.setAlertTimeout();
    }

    // sets a countdown to session timeout
    resetSessionCountdown() {
        if (this.params.logged_in) {
            // clear the old one
            clearTimeout(this.session_countdown);

            // set the new one (subtract 5s to account for transit times)
            this.session_countdown = setTimeout(() => this.redirectToLogin(), this.params.session_timeout * 1000 - 5000);
        }
    }

    // redirects the user to the login page
    redirectToLogin() {
        window.location.href = this.params.login_path;
    }

    // changes the current locale by rewriting the url to use the new locale
    changeLocale(new_locale) {
        // Build a new URL with the new locale and navigate there
        window.location.href = this.url_builder.build(window.location.pathname + window.location.search, { locale: new_locale });
    }

    // sets the title in h1.title and <title>
    setTitle(title) {
        $("title").text(`${this.params.site_name}: ${title}`);
        $("h1.title").text(title);
    }

    // shows the dropdown menu that extends from the 'submit' link in the navbar
    showHideSubmitMenu(link) {
        // only load if haven't loaded before
        const dropdown = link.next("ul");
        if (!dropdown.data("loaded")) {
            dropdown.data("loaded", true);
            if (dropdown.is(":hidden")) link.dropdown("toggle");

            // show loading ind
            dropdown.find("div.inline-load-ind").show();

            dropdown.load(`${this.url_builder.build("forms")}?dropdown=1`, () => {
                // hide loading ind
                dropdown.find("div.inline-load-ind").hide();
            });
        }
    }

    // Shows alert at top of page
    // params.type - success, error, notice, alert
    // params.tag - a dashified tag (e.g. option-sets) identifying the creator of the alert, to be used later when clearing
    // params.msg - the message
    showAlert({ type, tag, msg }) {
        $("<div>")
            .addClass("alert")
            .addClass(this.alertTypeClass(type))
            .addClass(this.alertTagClass(tag))
            .html(`<strong>${i18n.t(`common.${type}.one`)}:</strong> ${msg}`)
            .prependTo($("#content"));
        this.setAlertTimeout();
    }

    // removes all alerts
    clearAlerts({ tag } = {}) {
        // remove all alerts with given tag, or all alerts if no tag given
        $(`.${tag ? this.alertTagClass(tag) : "alert"}`).remove();
    }

    // gets css for alerts with given type
    alertTypeClass(type) {
        return ALERT_CLASSES[type];
    }

    // gets css class for alerts with a given tag
    alertTagClass(tag) {
        return tag ? `alert-for-${tag}` : "";
    }

    // hides any success alerts after a delay
    setAlertTimeout() {
        setTimeout(() => $(".alert-success").slideUp(), 4000);
    }

    // Shows/hides loading indicator.
    loading(yn) {
        $("#glb-load-ind")[yn ? "show" : "hide"]();
    }

    setupLocaleForm() {
        $("a#locale_form_link").on("click", () => {
            $("#locale_form").css("display", "inline-block");
            $("a#locale_form_link").hide();
            return false;
        });

        $("#locale_form select").on("change", (e) => {
            this.changeLocale($(e.currentTarget).val());
            return false;
        });
    }

    setupSubmitResponseDropdown() {
        $("#submitResponse").on("click", (e) => {
            this.showHideSubmitMenu($(e.currentTarget));
            return false;
        });
    }

    preventDoubleSubmission() {
        $("form").preventDoubleSubmission();
    }
}

csync.App = App;

