/*!
 * Color mode toggler for Bootstrap's docs (https://getbootstrap.com/)
 * Copyright 2011-2024 The Bootstrap Authors
 * Licensed under the Creative Commons Attribution 3.0 Unported License.
 *
 * Modified Date: August 25, 2024
 */

import Cookies from 'js-cookie';

(() => {
    "use strict";

    // Constants
    const THEME_COOKIE_NAME = "theme";
    const COOKIE_EXPIRES_DAYS = 5;

    // Define SVG symbols
    const svgIcons = `
    <svg xmlns="http://www.w3.org/2000/svg" style="display: none;">
        <symbol fill="currentColor" id="sun-fill" class="bi bi-sun-fill" viewBox="0 0 16 16">
          <path d="M8 12a4 4 0 1 0 0-8 4 4 0 0 0 0 8M8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0m0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13m8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5M3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8m10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0m-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0m9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707M4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708"/>
        </symbol>
        <symbol fill="currentColor" id="moon-stars-fill" class="bi bi-moon-stars-fill" viewBox="0 0 16 16">
          <path d="M6 .278a.77.77 0 0 1 .08.858 7.2 7.2 0 0 0-.878 3.46c0 4.021 3.278 7.277 7.318 7.277q.792-.001 1.533-.16a.79.79 0 0 1 .81.316.73.73 0 0 1-.031.893A8.35 8.35 0 0 1 8.344 16C3.734 16 0 12.286 0 7.71 0 4.266 2.114 1.312 5.124.06A.75.75 0 0 1 6 .278"/>
          <path d="M10.794 3.148a.217.217 0 0 1 .412 0l.387 1.162c.173.518.579.924 1.097 1.097l1.162.387a.217.217 0 0 1 0 .412l-1.162.387a1.73 1.73 0 0 0-1.097 1.097l-.387 1.162a.217.217 0 0 1-.412 0l-.387-1.162A1.73 1.73 0 0 0 9.31 6.593l-1.162-.387a.217.217 0 0 1 0-.412l1.162-.387a1.73 1.73 0 0 0 1.097-1.097zM13.863.099a.145.145 0 0 1 .274 0l.258.774c.115.346.386.617.732.732l.774.258a.145.145 0 0 1 0 .274l-.774.258a1.16 1.16 0 0 0-.732.732l-.258.774a.145.145 0 0 1-.274 0l-.258-.774a1.16 1.16 0 0 0-.732-.732l-.774-.258a.145.145 0 0 1 0-.274l.774-.258c.346-.115.617-.386.732-.732z"/>
        </symbol>
        <symbol fill="currentColor" id="circle-half" class="bi bi-circle-half" viewBox="0 0 16 16">
          <path d="M8 15A7 7 0 1 0 8 1zm0 1A8 8 0 1 1 8 0a8 8 0 0 1 0 16"/>
        </symbol>
        <symbol fill="currentColor" id="check2" class="bi bi-check2" viewBox="0 0 16 16">
          <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0"/>
        </symbol>
    </svg>
  `;

    // Append SVG icons to the document
    function appendSVGIcons() {
        // Check if the SVG container already exists to prevent multiple insertions
        if (!document.getElementById("svg-icons-container")) {
            const svgContainer = document.createElement("div");
            svgContainer.id = "svg-icons-container"; // Assign an ID for easier reference
            svgContainer.innerHTML = svgIcons;
            document.body.appendChild(svgContainer);
        }
    }

    // Event listeners
    document.addEventListener("DOMContentLoaded", appendSVGIcons);
    document.addEventListener("turbo:frame-load", appendSVGIcons);
    document.addEventListener("turbo:load", appendSVGIcons);

    const getStoredTheme = () => Cookies.get(THEME_COOKIE_NAME);

    const setStoredTheme = (theme) =>
        Cookies.set(THEME_COOKIE_NAME, theme, {
            expires: COOKIE_EXPIRES_DAYS,
            sameSite: "None",
            secure: true,
        });

    const getPreferredTheme = () => {
        const storedTheme = getStoredTheme();
        if (storedTheme) {
            return storedTheme;
        }
        return window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light";
    };

    const setTheme = (theme) => {
        if (theme === "auto") {
            theme = window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light";
        }
        document.documentElement.setAttribute("data-bs-theme", theme);
    };

    const updateThemeUI = (theme, focus = false) => {
        const themeToggleElements = document.querySelectorAll('[data-bs-theme-value]');
        themeToggleElements.forEach(element => {
            const isActive = element.getAttribute('data-bs-theme-value') === theme;
            const targetLi = element.closest('li');
            if (isActive) {
                targetLi.classList.add('active');
            } else {
                targetLi.classList.remove('active');
            }
        });

        // Update the button icon based on the selected theme
        const themeIconActive = document.querySelector('.theme-icon-active use');
        if (themeIconActive) {
            const iconMap = {
                light: '#sun-fill',
                dark: '#moon-stars-fill',
                auto: '#circle-half'
            };
            themeIconActive.setAttribute('href', iconMap[theme]);
        }

        if (focus) {
            const activeElement = document.querySelector('[data-bs-theme-value].active');
            if (activeElement) {
                activeElement.focus();
            }
        }
    };

    document.addEventListener("DOMContentLoaded", () => {
        const initialTheme = getPreferredTheme();
        setTheme(initialTheme);
        updateThemeUI(initialTheme);
    });

    document.addEventListener("click", (event) => {
        if (event.target.matches("[data-bs-theme-value]")) {
            const theme = event.target.getAttribute("data-bs-theme-value");
            setStoredTheme(theme);
            setTheme(theme);
            updateThemeUI(theme);
        }
    });
})();

