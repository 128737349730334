const MAX_RELOAD_COUNT = 120;

class DashboardView extends csync.Views.ApplicationView {
  get el() { return 'body.dashboard'; }

  get events() {
    return {
      'click a.full-screen': 'handleFullScreen',
      'click a.toggle-map': 'handleExpandMap',
      'click a.reload-ajax': 'reloadAjax', // For testing
    };
  }

  initialize(params) {
    this.params = params;
    this.reloadCount = 0;
    this.resetReloadTimer();
    this.params.map.serialization_key = this.params.project_id;
    this.mapView = new csync.Views.DashboardMapView(this.params.map);
  }

  handleFullScreen(e) {
    e.preventDefault();
    this.setFullScreen('toggle');
  }

  handleExpandMap(e) {
    e.preventDefault();
    this.setExpandedMap('toggle');
  }

  resetReloadTimer() {
    this.cancelReloadTimer();
    this.reloadTimer = setTimeout(this.reloadAjax.bind(this), this.params.reloadInterval * 1000);
  }

  cancelReloadTimer() {
    if (this.reloadTimer) {
      clearTimeout(this.reloadTimer);
    }
  }

  reloadAjax() {
    this.reloadCount += 1;
    if (this.reloadCount > MAX_RELOAD_COUNT) {
      this.reloadPage();
    }
  }

  handleReloadSuccess(data) {
    $('.stats').html(data.stats);
    this.mapView.update_map(data.response_locations);
    this.resetReloadTimer();
    this.reloadRequest = null;
  }

  // Reloads the page via full refresh to avoid memory issues.
  reloadPage() {
    const nonce = Math.floor(Math.random() * 1000000 + 1);
    window.location.href = `${csync.AppInit.url_builder.build('')}?r=${nonce}`;
  }

  // Enables/disables full screen mode. Uses stored setting if no param given.
  // Toggles setting if 'toggle' given.
  setFullScreen(value) {
    const full = this.viewSetting('full-screen', value);

    if (full) {
      $('footer').hide();
      $('#main-nav').hide();
      $('.left-info').hide();
      $('a.full-screen svg').remove('svg.bi-arrows-angle-expand').html(`<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrows-angle-contract" viewBox="0 0 16 16">
      <path fill-rule="evenodd" d="M.172 15.828a.5.5 0 0 0 .707 0l4.096-4.096V14.5a.5.5 0 1 0 1 0v-3.975a.5.5 0 0 0-.5-.5H1.5a.5.5 0 0 0 0 1h2.768L.172 15.121a.5.5 0 0 0 0 .707M15.828.172a.5.5 0 0 0-.707 0l-4.096 4.096V1.5a.5.5 0 1 0-1 0v3.975a.5.5 0 0 0 .5.5H14.5a.5.5 0 0 0 0-1h-2.768L15.828.879a.5.5 0 0 0 0-.707"/>
    </svg>`);

    // No need to unset things if toggle not given since it's only called that way on page load
    // and the default is to hide.
    } else if (value === 'toggle') {
      $('footer').show();
      $('#main-nav').show();
      $('.left-info').show();
      $('a.full-screen svg').remove('.bi-arrows-angle-contract').html(`<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrows-angle-expand" viewBox="0 0 16 16">
      <path fill-rule="evenodd" d="M5.828 10.172a.5.5 0 0 0-.707 0l-4.096 4.096V11.5a.5.5 0 0 0-1 0v3.975a.5.5 0 0 0 .5.5H4.5a.5.5 0 0 0 0-1H1.732l4.096-4.096a.5.5 0 0 0 0-.707m4.344-4.344a.5.5 0 0 0 .707 0l4.096-4.096V4.5a.5.5 0 1 0 1 0V.525a.5.5 0 0 0-.5-.5H11.5a.5.5 0 0 0 0 1h2.768l-4.096 4.096a.5.5 0 0 0 0 .707"/>
    </svg>`);
    }

    // Set link text
    $('a.full-screen span').text(i18n.t(`dashboard.${full ? 'exit' : 'enter'}_full_screen`));
  }

  // Enables/disables expanded map. Uses stored setting if no param given.
  // Toggles setting if 'toggle' given.
  // Always enables full screen if expanding map.
  // When collapsing map, disables full screen if it wasn't on when map was expanded.
  setExpandedMap(value) {
    const expand = this.viewSetting('expanded-map', value);

    if (expand) {
      const wasFull = this.viewSetting('full-screen');
      this.viewSetting('screen-full-before-map-expand', wasFull);
      this.setFullScreen(true);

      $('#content').addClass('expanded-map');

    // No need to unset things if toggle not given since it's only called that way on page load
    // and the default is to hide.
    } else if (value === 'toggle') {
      const wasFull = this.viewSetting('screen-full-before-map-expand');
      if (!wasFull) this.setFullScreen(false);

      $('#content').removeClass('expanded-map');
    }
  }

  viewSetting(settingName, value) {
    let bool = JSON.parse(localStorage.getItem(settingName));

    // Return unchanged if no value given.
    if (typeof value === 'undefined') return bool;

    // Toggle if requested.
    else if (value === 'toggle') bool = !bool;

    // Else set directly.
    else bool = value;

    localStorage.setItem(settingName, bool);
    return bool;
  }
};

csync.Views.DashboardView = DashboardView;
