/**
 * csync.Views.QuestionFormTagField is a class that represents a tag field in a question form.
 * It provides functionalities for initializing the tag field, binding events, and managing tags.
 *
 * @constructor
 * @param {Object} params - Parameters for the tag field.
 *
 * @property {Object} params - Parameters for the tag field.
 * @property {Array} items - Array to store the tags.
 * @property {boolean} silent - Flag to control event handling.
 * @property {Object} input - The select2 input field.
 *
 * @method initialize - Initializes the select2 input field with specific settings.
 * @method bindEvents - Binds select and unselect events to the input field.
 * @method updateInputDataTag - Updates the input data tag.
 * @method add_tag - Adds a new tag to the items array and the form.
 * @method remove_tag - Removes a tag from the items array, the input field, and the form.
 */
import $ from 'jquery';
import 'select2';

csync.Views.QuestionFormTagField = class {
    constructor(params) {
        this.params = params;
        this.items = [];
        this.silent = false;
        this.input = this.findTagInput();

        if (this.input.length > 0) {
            this.initialize();
            this.bindEvents();
        }
    }

    findTagInput() {
        return $("select[id$='_tag_ids']");
    }

    initialize() {
        try {
            this.input.select2({
                theme: "bootstrap-5",
                multiple: true,
                placeholder: i18n.t("tag.type_to_add_new"),
                tags: true,
                tokenSeparators: [","],
                createTag: (params) => ({
                    id: params.term,
                    text: params.term,
                    newOption: true,
                }),
                templateResult: (data) => {
                    const $result = $("<span></span>");
                    $result.text(data.text);

                    if (data && data.id && data.text && data.id.trim() === data.text.trim()) {
                        $result.append(` <span class="details create_new">[${i18n.t("tag.new_tag")}]</span>`);
                    }

                    return $result;
                },
                ajax: {
                    multiple: true,
                    url: `${this.params.suggest_tags_path}.json`,
                    dataType: "json",
                    delay: 250,
                    processResults: (data) => ({
                        results: data && Array.isArray(data.tags)
                            ? data.tags.map((tag) => ({ id: tag.id || tag.name, text: tag.name }))
                            : [],
                    }),
                    cache: true,
                },
            });
        } catch (error) {
            console.error("An error occurred in the initialize method:", error);
        }
    }

    bindEvents() {
        this.input.on("select2:select", (e) => !this.silent && this.add_tag(e.params.data, this.params.project_id));
        this.input.on("select2:unselect", (e) => !this.silent && this.remove_tag(e.params.data));
    }

    updateInputDataTag() {
        $('input[name="questioning[question_attributes][tag_ids][]"]').attr(
            "name",
            "questioning[question_attributes][tag_ids]"
        );
        const selectedIds = this.input.select2("data").map((data) => data.id);
        $("input[name*='[tag_ids]']").val(selectedIds.join(","));
    }

    add_tag = (item, projectId) => {
        const form = $(".question_form").length ? $(".question_form") : $(".questioning_form");
        const inputNamePrefix = `question${
            $(".question_form").length ? "" : "ing[question_attributes]"
        }[tags_attributes][${Math.floor(Math.random() * 999999999)}]`;

        if (item.text.trim() === item.id.trim()) {
            form.append(
                `<input type="hidden" name="${inputNamePrefix}[name]" value="${item.text.trim()}">` +
                    `<input type="hidden" name="${inputNamePrefix}[project_id]" value="${projectId}">`
            );
            this.items.push(item);
        } else {
            this.updateInputDataTag();
        }
    };

    remove_tag = (item) => {
        let select2Data = this.input.select2("data");
        select2Data = select2Data.filter((dataItem) => dataItem.id !== item.id);
        this.input.select2("data", select2Data);
        this.input.find(`option[value='${item.id}']`).remove();
        this.input.trigger("change");
        $(`input[name*='[tags_attributes]'][value='${item.id}']`).remove();
        this.updateInputDataTag();
    };
};
