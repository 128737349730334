/**
 * Perfect Scrollbar Initialization
 */

import PerfectScrollbar from 'perfect-scrollbar';

document.addEventListener("turbo:load", function () {
  const scrollViews = {
    vertical: {
      elements: document.getElementsByClassName('vertical-scroll-view'),
      options: { wheelPropagation: false }
    },
    horizontal: {
      elements: document.getElementsByClassName('horizontal-scroll-view'),
      options: { wheelPropagation: false, suppressScrollY: true }
    },
    both: {
      elements: document.getElementsByClassName('both-scrollbars-scroll-view'),
      options: { wheelPropagation: false, suppressScrollY: true }
    }
  };

  // Initialize Perfect Scrollbar for each type of scroll view
  for (const key in scrollViews) {
    const { elements, options } = scrollViews[key];
    if (elements.length > 0) {
      Array.from(elements).forEach(element => {
        new PerfectScrollbar(element, options);
      });
    }
  }
});

