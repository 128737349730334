//
import _ from "underscore";
import { TurboConfirm } from "@rolemodel/turbo-confirm";

const tc = new TurboConfirm({
    activeClass: "show"
});

csync.Views.RegenerableFieldView = class RegenerableFieldView extends csync.Views.ApplicationView {
  get events() {
    return {
      'click .regenerate': 'regenerateField'
    };
  }

  regenerateField(event) {
    event.preventDefault();
    const target = $(event.currentTarget);
    const handler = target.data('handler');
    const confirmMsg = target.data('confirm-msg');
    const container = target.closest('.regenerable-field');
    const displayEl = container.find('span[data-value]');
    const inlineLoadInd = container.find('div#inline_load_ind');
    const successIndicator = container.find('.success');
    const errorIndicator = container.find('.failure');

    // If confirm text is provided and there is a current value, show a confirmation dialog
    if (confirmMsg && displayEl.data('value')) {
      tc.confirmWithContent({
        "#confirm-title": i18n.t("common.regenerate"),
        "#confirm-body": confirmMsg,
      }).then((response) => {
        if (response) {
          this.proceedWithRegeneration(target, handler, inlineLoadInd, successIndicator, errorIndicator, displayEl);
        } else {
          $("#confirm").hide(); // Hide confirmation dialog on cancel
        }
      });
    } else {
      // If no confirmation is needed, proceed directly
      this.proceedWithRegeneration(target, handler, inlineLoadInd, successIndicator, errorIndicator, displayEl);
    }
  }

  proceedWithRegeneration(target, handler, inlineLoadInd, successIndicator, errorIndicator, displayEl) {
    // Disable the button and ensure that only the loading indicator is shown
    target.attr('disabled', 'disabled');
    successIndicator.hide();
    errorIndicator.hide();
    inlineLoadInd.show();

    // Include the CSRF token in the request headers
    const csrfToken = $('meta[name="csrf-token"]').attr('content');
    $.ajax({
      method: 'patch',
      url: handler,
      headers: {
        'X-CSRF-Token': csrfToken
      },
      success: (data) => {
        // Trigger an event that other views can subscribe to, with the response data as the first param.
        this.$el.trigger('regenerable-field:updated', [data]);
        if (displayEl.length > 0) {
          displayEl.data({ value: data.value });
          displayEl.text(data.value);
        }
        inlineLoadInd.hide();
        successIndicator.show();
      },
      error: () => {
        if (csync.unloading) return;
        inlineLoadInd.hide();
        errorIndicator.show();
      },
      complete: () => {
        target.removeAttr('disabled');
      },
    });
  }
};