//

csync.Views.SettingsView = class SettingsView extends csync.Views.ApplicationView {
  get el() { return 'form.setting_form'; }

  get events() {
    return {
      'click .adapter-settings a.show-credential-fields': 'show_change_credential_fields',
      'click .using-incoming-sms-token': 'show_using_incoming_sms_token_modal',
      'click .using-universal-sms-token': 'show_using_universal_sms_token_modal',
      'click .credential-fields input[type=checkbox]:checked': 'clear_sms_fields',
    };
  }

  initialize(options) {
    this.need_credentials = options.need_credentials || {};
    return this.show_credential_fields_with_errors();
  }

  show_change_credential_fields(event) {
    this.$(event.target).hide();
    this.$(event.target).closest('.adapter-settings').find('.credential-fields').show();
    return false;
  }

  show_using_universal_sms_token_modal(event) {
    event.preventDefault();
    csync.AppInit.loading(true);

    return $.ajax({
      url: csync.AppInit.url_builder.build('settings', 'using_incoming_sms_token_message?missionless=1'),
      success(data) {
        return new csync.Views.UsingIncomingSmsTokenModalView({ html: data.message.replace(/\n/g, '<br/>') });
      },
      complete() {
        return csync.AppInit.loading(false);
      },
    });
  }

  show_using_incoming_sms_token_modal(event) {
    event.preventDefault();
    csync.AppInit.loading(true);

    return $.ajax({
      url: csync.AppInit.url_builder.build('settings', 'using_incoming_sms_token_message'),
      success(data) {
        return new csync.Views.UsingIncomingSmsTokenModalView({ html: data.message.replace(/\n/g, '<br/>') });
      },
      complete() {
        return csync.AppInit.loading(false);
      },
    });
  }

  show_credential_fields_with_errors() {
    const adapters = this.$('.form-field.has-errors:hidden').closest('.adapter-settings');
    this.$(adapters).find('.credential-fields').show();
    return this.$(adapters).find('a.show-credential-fields').hide();
  }

  clear_sms_fields(event) {
    const inputs = this.$(event.target).closest('.adapter-settings').find('input[type=text]');
    return this.$(inputs).val('');
  }
};
