// Global dependencies
import Rails from '@rails/ujs';
import { i18n } from '../i18n';
window.i18n = i18n;
import '../src/scripts/utils/core_ext/array';
import '../src/scripts/utils/core_ext/string';
import '../src/scripts/utils/core_ext/object';
import 'jquery/src/jquery'

window.bootstrap = require('bootstrap/dist/js/bootstrap.bundle');
window.maplibregl = require('maplibre-gl/dist/maplibre-gl');

//trumbowyg
import 'trumbowyg';
import 'trumbowyg/dist/langs/ar';
import 'trumbowyg/dist/langs/es';
import 'trumbowyg/dist/langs/fr';
//import 'jquery-fileupload/basic-plus';

import 'bootstrap5-toggle';

import 'underscore';

import '../src/libs/backbone/backbone';
import '../src/libs/backbone/backbone_rails_sync';
import '../src/libs/backbone/backbone_datalink';

import 'select2';
import 'flatpickr';

// Initialize libs
import '../src/scripts/init/perfect-scrollbar';
import '../src/scripts/init/clipboardjs';
import '../src/scripts/init/apexcharts';
import '../src/scripts/init/bootstrap-auto-dark-mode';

// Global
import '../src/scripts/utils/core_ext/jquery_ext';
import '../src/scripts/legacy/common';
import '../src/scripts/utils/sassafras/utils';
import '../src/scripts/utils/select2_option_builder';

// Main
import '../src/scripts/setup';
import '../src/scripts/main';

// Superclasses that need to come first due to inheritance';
import '../src/scripts/legacy/views/control/control';
import '../src/scripts/legacy/models/report/object_menu';
import '../src/scripts/legacy/views/report/display';
import '../src/scripts/legacy/views/report/edit_pane';
import '../src/scripts/views/application_view';
import '../src/scripts/views/form_view';

//
import '../src/scripts/legacy/models/named_item';
import '../src/scripts/legacy/models/option_node';
import '../src/scripts/legacy/models/report/attrib_menu';
import '../src/scripts/legacy/models/report/calc_type_menu';
import '../src/scripts/legacy/models/report/errors';
import '../src/scripts/legacy/models/report/form_menu';
import '../src/scripts/legacy/models/report/option_set_menu';
import '../src/scripts/legacy/models/report/question_menu';
import '../src/scripts/legacy/models/report/report';
import '../src/scripts/legacy/views/draggable_list';
import '../src/scripts/legacy/views/option_levels_field';
import '../src/scripts/legacy/views/option_set_form';
import '../src/scripts/legacy/views/options_field';
import '../src/scripts/legacy/views/project_change_dropdown';
import '../src/scripts/legacy/views/question_form';
import '../src/scripts/legacy/views/question_form_tag_field';
import '../src/scripts/legacy/views/standard_import_form';
import '../src/scripts/legacy/views/control/multiselect';
import '../src/scripts/legacy/views/control/radio_group';
import '../src/scripts/legacy/views/control/select';
import '../src/scripts/legacy/views/report/bar_chart_display';
import '../src/scripts/legacy/views/report/disagg_question_selector';
import '../src/scripts/legacy/views/report/display_options_edit_pane';
import '../src/scripts/legacy/views/report/edit_view';
import '../src/scripts/legacy/views/report/fields_edit_pane';
import '../src/scripts/legacy/views/report/field_selector';
import '../src/scripts/legacy/views/report/field_selector_set';
import '../src/scripts/legacy/views/report/filters_edit_pane';
import '../src/scripts/legacy/views/report/form_selection_edit_pane';
import '../src/scripts/legacy/views/report/form_summary_display';
import '../src/scripts/legacy/views/report/grouping_edit_pane';
import '../src/scripts/legacy/views/report/question_selection_edit_pane';
import '../src/scripts/legacy/views/report/report_type_edit_pane';
import '../src/scripts/legacy/views/report/table_display';
import '../src/scripts/legacy/form_question_chooser';
import '../src/scripts/legacy/location_picker';
import '../src/scripts/legacy/responses';
import '../src/scripts/legacy/string_ext';
import '../src/scripts/legacy/url_builder';

//
import '../src/scripts/views/answer_map_view';
import '../src/scripts/views/batch_actions_view';
import '../src/scripts/views/broadcasts_view';
import '../src/scripts/views/cascading_selects_view';
import '../src/scripts/views/dashboard_map_view';
import '../src/scripts/views/dashboard_view';
import '../src/scripts/views/export_csv_view';
import '../src/scripts/views/file_uploader_manager';
import '../src/scripts/views/file_uploader_view';
import '../src/scripts/views/form_hint_view';
import '../src/scripts/views/form_items_draggable_list_view';
import '../src/scripts/views/form_items_view';
import '../src/scripts/views/form_minimum_version_view';
import '../src/scripts/views/form_settings_view';
import '../src/scripts/views/group_modal_view';
import '../src/scripts/views/print_form_view';
import '../src/scripts/views/question_form_view';
import '../src/scripts/views/questioning_form_view';
import '../src/scripts/views/questions_tags_view';
import '../src/scripts/views/regenerable_field_view';
import '../src/scripts/views/repeat_group_form_view';
import '../src/scripts/views/report_page_view';
import '../src/scripts/views/report_view';
import '../src/scripts/views/response_condition_checker';
import '../src/scripts/views/response_condition_group_checker';
import '../src/scripts/views/response_condition_manager';
import '../src/scripts/views/response_form_repeat_view';
import '../src/scripts/views/response_form_view';
import '../src/scripts/views/response_list_view';
import '../src/scripts/views/return_to_draft_view';
import '../src/scripts/views/search_form_view';
import '../src/scripts/views/settings_view';
import '../src/scripts/views/sms_test_console_view';
import '../src/scripts/views/user_groups_modal_view';
import '../src/scripts/views/user_list_view';
import '../src/scripts/views/user_login_instructions';
import '../src/scripts/views/user_profile_form_view';
import '../src/scripts/views/using_incoming_sms_token_view';

