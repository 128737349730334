class UserListView extends csync.Views.ApplicationView {
  el = '.table-wrapper';

  constructor() {
    super();
    this.user_table_view = csync.batch_actions_views.user;
    this.modal_view = new csync.Views.UserGroupsModalView({ user_table_view: this.user_table_view });
    this.alert = this.$el.find('div.alert');
    this.bindEvents();
  }

  bindEvents() {
    // Use arrow functions to avoid manual binding
    $(document).on('click', '.list-groups', this.show_groups_modal);
    $(document).on('click', '.add-to-group', this.add_to_group_modal);
    $(document).on('click', '.remove-from-group', this.remove_from_group_modal);
  }

  show_groups_modal = (event) => {
    event.preventDefault();
    csync.AppInit.loading(true);
    this.fetch_group_listing(csync.AppInit.url_builder.build('user_groups'));
  }

  add_to_group_modal = (event) => {
    event.preventDefault();
    if (this.selected_users().length > 0) {
      csync.AppInit.loading(true);
      this.fetch_group_listing(csync.AppInit.url_builder.build('user_groups?add=true'), 'add');
    } else {
      this.show_alert(i18n.t('layout.no_selection'));
    }
  }

  remove_from_group_modal = (event) => {
    event.preventDefault();
    if (this.selected_users().length > 0) {
      csync.AppInit.loading(true);
      this.fetch_group_listing(csync.AppInit.url_builder.build('user_groups?remove=true'), 'remove');
    } else {
      this.show_alert(i18n.t('layout.no_selection'));
    }
  }

  selected_users = () => {
    const user_checkboxes = this.user_table_view.get_selected_items();
    return Array.from(user_checkboxes).map(cb => $(cb).data('userId'));
  }

  fetch_group_listing = (url, mode) => {
    $.ajax({
      url,
      method: 'GET',
      success: (html) => {
        this.modal_view.set_body(html);
        this.modal_view.set_mode(mode);
        csync.AppInit.loading(false);
        this.modal_view.show();
      },
    });
  }

  show_alert = (message) => {
    this.alert.html(message).addClass('alert-danger').show();
    this.alert.delay(2500).fadeOut('slow', this.user_table_view.reset_alert);
  }
}

csync.Views.UserListView = UserListView;
